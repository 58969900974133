import React from "react";
import { Row } from "react-bootstrap";
import PropTypes from "prop-types";
import {
  withStyles,
  MuiThemeProvider,
  createMuiTheme,
} from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import SelectField from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import ActionLogin from "@material-ui/icons/LockOpen";
import ActionStart from "@material-ui/icons/Done";
import ActionCancel from "@material-ui/icons/Cancel";
import ActionSettings from "@material-ui/icons/Settings";
import Button from "@material-ui/core/Button";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import red from "@material-ui/core/colors/red";
import Divider from "@material-ui/core/Divider";
import BarcodeReader from "JOB-master";
import zxing from "instascan/src/zxing.js";
import { findDOMNode } from "react-dom";
import Select from "react-select";

import MicIcon from "@material-ui/icons/Mic";
// Libray text to speech
import Speech from "react-speech";

const ZXing = zxing();

function hasGetUserMedia() {
  return !!(
    navigator.getUserMedia ||
    navigator.webkitGetUserMedia ||
    navigator.mozGetUserMedia ||
    navigator.msGetUserMedia
  );
}

const styles = (theme) => ({
  button: {
    margin: 12,
    alignSelf: "stretch",
  },
  exampleImageInput: {
    cursor: "pointer",
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    width: "100%",
    opacity: 0,
  },
  menuItem: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& $primary, & $icon": {
        color: theme.palette.common.white,
      },
    },
  },
  primary: {},
  icon: {},
  button: {
    margin: theme.spacing.unit,
  },
  contentAppBar: {
    alignItems: "center",
  },
});
var containerStyle = {
  overflow: "hidden",
  position: "relative",
  width: "100%",
  paddingTop: "100%",
};
var viewFinderStyle = {
  top: 0,
  left: 0,
  zIndex: 1,
  boxSizing: "border-box",
  border: "50px solid rgba(0, 0, 0, 0.3)",
  boxShadow: "inset 0 0 0 5px rgba(255, 0, 0, 0.5)",
  position: "absolute",
  width: "100%",
  height: "100%",
};
var previewStyle = {
  top: 0,
  left: 0,
  display: "block",
  position: "absolute",
  overflow: "hidden",
  width: "100%",
  height: "100%",
};
var _extends =
  Object.assign ||
  function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];
      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }
    return target;
  };
var videoPreviewStyle = _extends({}, previewStyle, {
  objectFit: "cover",
  transform: undefined,
});
var voiceSpeechDone = [];
var currentVoiceSpeechID = "0";

function Transition(props) {
  return <Slide direction="up" {...props} />;
}
const numWords = {
  cero: 0,
  uno: 1,
  dos: 2,
  tres: 3,
  cuatro: 4,
  cinco: 5,
  seis: 6,
  siete: 7,
  ocho: 8,
  nueve: 9,
};

class Principal extends React.Component {
  static defaultProps = {
    audio: false,
    className: "",
    height: 100,
    muted: false,
    onUserMedia: () => {},
    screenshotFormat: "image/webp",
    width: 200,
  };
  static mountedInstances = [];

  static userMediaRequested = false;
  static barcodeLoaded = false;

  constructor(props) {
    super(props);

    let url = new URL(window.location.href);
    let empresaURL = url.searchParams.get("empresa");
    let operarioURL = url.searchParams.get("operario");

    //history.replaceState({}, document.title, "/");

    this.state = {
      document: document,
      url: url,
      seleccion: 0,
      newPass1: "",
      newPass2: "",
      almacen: "",
      maquina: "",
      idioma: "",
      empresa: empresaURL ? empresaURL : "",
      operario: operarioURL ? operarioURL : "",
      clave: "",
      inputTerminal: "",
      actionId: 0,
      showPassword: false,
      focusedElementChange: this.props.focusedElementChange,
      hasUserMedia: false,
      readBarcode: false,
      lastCodeSent: "",
      voices: null,
      micIsActive: false,
    };
    this.botonClick = this.botonClick.bind(this);
    this.abajoClick = this.abajoClick.bind(this);
    this.arribaClick = this.arribaClick.bind(this);
    this.volverClick = this.volverClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.botonClick1 = this.botonClick1.bind(this);
    this.botonClick2 = this.botonClick2.bind(this);
    this.handleKeyboardKeyPressed = this.handleKeyboardKeyPressed.bind(this);
    this.handleOnFocus = this.handleOnFocus.bind(this);
    this.mountReader = this.mountReader.bind(this);
    this.unmountReader = this.unmountReader.bind(this);
    this.getScan = this.getScan.bind(this);
    this.barcodeResult = this.barcodeResult.bind(this);
    this.scannerClick = this.scannerClick.bind(this);
    this.buildOptionsSelect = this.buildOptionsSelect.bind(this);
    this.getVoiceSpeech = this.getVoiceSpeech.bind(this);
    this.loadVoices = this.loadVoices.bind(this);

    setInterval(
      function () {
        //if(this.props.cabecera === "Acción"){
        this.mountReader();
        this.getScan();
        //}
      }.bind(this),
      2000
    ); //here you can set the zxing interval
  }

  componentDidMount() {
    this.loadVoices();
  }

  componentDidUpdate() {
    setTimeout(function () {
      if (!voiceSpeechDone[currentVoiceSpeechID]) {
        let buttonSpeech = document.querySelector(".rs-play");
        if (buttonSpeech) {
          buttonSpeech.click();
          voiceSpeechDone[currentVoiceSpeechID] = true;
        }
      }
    }, 200);
  }

  loadVoices() {
    setTimeout(
      function () {
        const voices = window.speechSynthesis.getVoices();
        // console.log("voces", voices);
        if (!voices || voices.length === 0) {
          this.loadVoices();
        } else {
          this.setState({
            voices: voices,
          });
        }
      }.bind(this),
      500
    );
  }

  mountReader() {
    //console.log("Iniciando en mountReader()...");
    //console.log(this.stream);
    if (
      this.props.cabecera === "Acción" &&
      !this.barcodeLoaded &&
      this.props.scanner
    ) {
      //console.log("Entrando en mountReader()...");
      this.barcodeLoaded = true;
      if (!hasGetUserMedia()) return;

      Principal.mountedInstances.push(this);

      if (!Principal.userMediaRequested) {
        this.requestUserMedia();
      }
      this._analyzer = new Analyzer(this.video);

      BarcodeReader.Init();
      BarcodeReader.StreamCallback = function (result) {
        this.barcodeResult(result, this);
      }.bind(this);
      BarcodeReader.DecodeStream(this.video);
    } else if (this.barcodeLoaded && !this.props.scanner) {
      //console.log("Desmontando reader...");
      this.unmountReader();
    } else {
      //console.log("Saltando mountReader()...");
    }
  }

  barcodeResult(result, currentThis) {
    if (result.length > 0) {
      if (this.state.lastCodeSent != result[0].Value) {
        console.log("Código de barras leído: " + result[0].Value);
        this.props.appClick(
          this.props.cabecera,
          1,
          undefined,
          result[0].Value,
          "0"
        );
      }
      currentThis.setState({
        inputTerminal: result[0].Value,
        lastCodeSent: result[0].Value,
      });
      this.scannerClick();
    }
  }

  unmountReader() {
    //console.log("Entrando en unmountReader()...");
    const index = Principal.mountedInstances.indexOf(this);
    Principal.mountedInstances.splice(index, 1);

    if (Principal.mountedInstances.length === 0 && this.state.hasUserMedia) {
      //console.log("Entrando en unmountReader() -> dentro del IF...");
      if (this.stream.stop) {
        //console.log("Entrando en unmountReader() -> dentro del IF -> this.stream.stop()...");
        this.stream.stop();
      } else {
        if (this.stream.getVideoTracks) {
          //console.log("Entrando en unmountReader() -> dentro del IF -> this.stream.getVideoTracks()...");
          this.stream.getVideoTracks().map((track) => track.stop());
        }
        if (this.stream.getAudioTracks) {
          //console.log("Entrando en unmountReader() -> dentro del IF -> this.stream.getAudioTracks()...");
          this.stream.getAudioTracks().map((track) => track.stop());
        }
      }
      Principal.userMediaRequested = false;
      window.URL.revokeObjectURL(this.state.src);
      this.stream = null;
    }

    this.barcodeLoaded = false;
  }

  componentWillUnmount() {
    if (this.barcodeLoaded) {
      this.unmountReader();
    }
  }

  getScan() {
    if (this.props.cabecera === "Acción" && this.barcodeLoaded) {
      if (!this.state.hasUserMedia) return null;
      const canvas = this.getCanvas();
      let analysis = this._analyzer.analyze();
      if (!analysis) {
        return null;
      } else {
        //here you can extract the qr result
        //console.log("Resultado del analisis: " + analysis.result);
        if (this.state.lastCodeSent != analysis.result) {
          console.log("QR leído: " + analysis.result);
          this.props.appClick(
            this.props.cabecera,
            1,
            undefined,
            analysis.result,
            "0"
          );
        }
        this.setState({
          inputTerminal: analysis.result,
          lastCodeSent: analysis.result,
        });
        this.scannerClick();
      }

      return null;
    } else {
      //console.log("Saltando getScan()...");
    }
  }

  getCanvas() {
    const video = findDOMNode(this.video);

    if (!this.state.hasUserMedia || !video.videoHeight) return null;

    if (!this.ctx) {
      const canvas = document.createElement("canvas");
      const aspectRatio = video.videoWidth / video.videoHeight;

      canvas.width = video.clientWidth;
      canvas.height = video.clientWidth / aspectRatio;

      this.canvas = canvas;
      this.ctx = canvas.getContext("2d");
    }

    const { ctx, canvas } = this;
    ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

    return canvas;
  }

  requestUserMedia() {
    navigator.getUserMedia =
      navigator.getUserMedia ||
      navigator.webkitGetUserMedia ||
      navigator.mozGetUserMedia ||
      navigator.msGetUserMedia;

    const sourceSelected = (audioSource, videoSource) => {
      const constraints = {
        video: {
          //optional: [{sourceId: videoSource}],
          facingMode: "environment",
        },
        audio: false,
      };

      // if (this.props.audio) {
      //     constraints.audio = {
      //         optional: [{sourceId: audioSource}]
      //     };
      // }

      navigator.getUserMedia(
        constraints,
        (stream) => {
          Principal.mountedInstances.forEach((instance) =>
            instance.handleUserMedia(null, stream)
          );
        },
        (e) => {
          Principal.mountedInstances.forEach((instance) =>
            instance.handleUserMedia(e)
          );
        }
      );
    };

    if (this.props.audioSource && this.props.videoSource) {
      sourceSelected(this.props.audioSource, this.props.videoSource);
    } else if ("mediaDevices" in navigator) {
      navigator.mediaDevices
        .enumerateDevices()
        .then((devices) => {
          let audioSource = null;
          let videoSource = null;

          devices.forEach((device) => {
            if (device.kind === "audio") {
              audioSource = device.id;
            } else if (device.kind === "video") {
              videoSource = device.id;
            }
          });
          sourceSelected(audioSource, videoSource);
        })
        .catch((error) => {
          console.log(`${error.name}: ${error.message}`); // eslint-disable-line no-console
        });
    } else {
      MediaStreamTrack.getSources((sources) => {
        let audioSource = null;
        let videoSource = null;

        sources.forEach((source) => {
          if (source.kind === "audio") {
            audioSource = source.id;
          } else if (source.kind === "video") {
            videoSource = source.id;
          }
        });

        sourceSelected(audioSource, videoSource);
      });
    }

    Principal.userMediaRequested = true;
  }

  handleUserMedia(error, stream) {
    if (error) {
      this.setState({
        hasUserMedia: false,
      });

      return;
    }
    try {
      const src = window.HTMLMediaElement.srcObject(stream);

      this.stream = stream;
      this.setState({
        hasUserMedia: true,
        src,
      });

      this.props.onUserMedia();
    } catch (error) {
      this.stream = stream;
      this.video.srcObject = stream;
      this.setState({
        hasUserMedia: true,
      });
    }
  }

  getVoiceSpeech(text) {
    if (!this.state.voices) return "Cargando voces...";
    text = text.replace("[VOICE_SPEECH]", "");
    let text_components = text.split(";");
    currentVoiceSpeechID = text_components[0];
    text = text_components[1];
    let textStyle = {
      color: "#111",
      fontWeight: "100",
      fontSize: "0.8em",
      display: "block",
      width: "100%",
    };
    return (
      <div id="voice_speech" style={textStyle}>
        <Speech
          text={text}
          textAsButton
          // pitch="1"
          // rate="1"
          // volume="0.5"
          // lang="es-ES"
          voice={this.getVoiceByLang("es-ES")}
        />
      </div>
    );
  }

  getVoiceByLang(lang) {
    const voices = this.state.voices;
    // console.log("voces cargadas", voices);
    let voice = "default";
    for (let i = 0; i < voices.length; i++) {
      const current = voices[i];
      // console.log("voz comparada", current);
      if (current.lang === lang) {
        voice = current;
        break;
      }
    }
    // console.log("voz encontrada", voice);
    voice = voice !== undefined ? voice : voices[0];
    return voice.name;
  }

  formatText(text) {
    // if (this.hasOptions(text)) return "Elige una opción";
    let result = [];
    if (text.includes("\\n") && !text.includes("@")) {
      result = this.props.data.payload.screenContent.content
        .split("\n")
        .map((item, key) => {
          return (
            <span key={key}>
              {item.match(/\[([^\]]+)\]/) != null
                ? item.match(/\[([^\]]+)\]/)[1]
                : item}
              <br />
            </span>
          );
        });
    }
    let lines = text.split("@");
    lines.map((line) => {
      let components = line.split(";");
      let new_line = null;
      for (let i = components.length - 1; i >= 0; i--) {
        const element = components[i];
        if (i === components.length - 1) {
          new_line = element;
        } else {
          let current_check = "FONTSIZE";
          if (element.startsWith(current_check)) {
            let attribute = element.substr(current_check.length);
            new_line = <span style={{ fontSize: attribute }}>{new_line}</span>;
          }
          current_check = "FONTCOLOR";
          if (element.startsWith(current_check)) {
            let attribute = element.substr(current_check.length);
            new_line = <span style={{ color: attribute }}>{new_line}</span>;
          }
          current_check = "FONTITALIC";
          if (element.startsWith(current_check)) {
            new_line = <i>{new_line}</i>;
          }
          current_check = "FONTBOLD";
          if (element.startsWith(current_check)) {
            new_line = <b>{new_line}</b>;
          }
          current_check = "FONTUNDERLINE";
          if (element.startsWith(current_check)) {
            new_line = <u>{new_line}</u>;
          }
        }
      }
      result.push(new_line);
      result.push(<br />);
    });

    return result;
  }

  hasOptions(text) {
    if (text && text.length > 0 && text.startsWith("[OPTIONS_SELECT]"))
      return true;
    return false;
  }

  isVoiceSpeech(text) {
    if (text && text.length > 0 && text.startsWith("[VOICE_SPEECH]"))
      return true;
    return false;
  }

  buildOptionsSelect(content) {
    let options = [];
    // El formato de la cadena será: [OPTIONS_SELECT]id1,label 1;id2,label 2;...
    content = content.replace("[OPTIONS_SELECT]", "");
    let raw_options = content.split(";");
    raw_options.map((option) => {
      let option_components = option.split(",");
      options.push({
        label: option_components[1], //
        value: option_components[0],
      });
    });
    return (
      <Select
        className="react-select primary"
        classNamePrefix="react-select"
        placeholder="Elige"
        name="actionOptions"
        value={this.state.actionOption}
        options={options}
        onChange={(value) => {
          this.setState({
            actionOption: value,
            inputTerminal: value.value,
          });
        }}
      />
    );
  }

  mixedTextToNumber(input) {
    // Extrae todos los tokens que son palabras o números del 0 al 9
    if (!input) {
      return "";
    }
    let tokens = input.toLowerCase().match(/\w+|\d/g); // \d en lugar de \d+ para capturar solo dígitos individuales
    let numberArray = "";
    let isStopping = false;
    tokens.forEach((token) => {
      if (!isNaN(token)) {
        // Si el token es un número directo (0-9)
        numberArray += token;
      } else if (numWords.hasOwnProperty(token)) {
        // Si es un número escrito como palabra (cero - nueve)
        numberArray += numWords[token];
      } else {
        // Ignora cualquier palabra que no corresponda a un número del 0 al 9
        if (token === "para") {
          this.setState({
            micIsActive: !this.state.micIsActive,
          });
          isStopping = true;
        }
      }
    });
    let result = numberArray;

    if (isStopping) {
      this.setState({
        inputTerminal: this.state.inputTerminal + result,
      });
      this.props.stopListening();
    }
    return result;
  }
  handleMicClick = () => {
    if (this.state.micIsActive === false) {
      this.props.startListening();
    } else if (this.state.micIsActive === true) {
      this.setState({
        inputTerminal:
          this.state.inputTerminal +
          this.mixedTextToNumber(this.props.transcript),
      });
      this.props.stopListening();
    }
    this.setState({
      micIsActive: !this.state.micIsActive,
    });
  };

  render() {
    const themeRed = createMuiTheme({
      palette: {
        primary: {
          light: red[500],
          main: red[700],
          dark: red[900],
          contrastText: "#fff",
        },
      },
      typography: {
        useNextVariants: true,
      },
    });
    const { classes } = this.props;
    const colorVolver = "primary";
    console.log("Cabecera en Principal: -" + this.props.cabecera + "-");
    console.log("isLoading en Principal: -" + this.props.isLoading + "-");

    if (this.props.cabecera !== "Acción" && this.barcodeLoaded) {
      this.unmountReader();
    }

    if (this.props.cabecera !== "Acción" && this.state.scannerTagUsed) {
      this.setState({ scannerTagUsed: false });
    }

    if (this.state.actionId != this.props.actionId) {
      this.setState({ inputTerminal: "", actionId: this.props.actionId });
    }
    if (
      this.props.focusedElementChange.id != this.state.focusedElementChange.id
    ) {
      this.setState({
        focusedElementChange: this.props.focusedElementChange,
        [this.props.focusedElementChange.name]:
          this.props.focusedElementChange.value,
      });
    }

    if (this.props.isLoading) {
      return (
        <div>
          <CircularProgress size={80} thickness={5} />
          <h3>Cargando...</h3>
        </div>
      );
    } else {
      if (this.props.cabecera === "Login") {
        return (
          <div>
            <br />
            <TextField
              fullWidth
              placeholder="Código de empresa CXXXXXXXXX"
              label="Empresa"
              value={this.state.empresa}
              id="empresa"
              onChange={this.handleChange}
              inputProps={{
                name: "empresa",
              }}
              onFocus={this.handleOnFocus}
            />
            <br />
            <br />
            <TextField
              fullWidth
              placeholder="Usuario"
              label="Nombre del operario"
              value={this.state.operario}
              id="usuario"
              onChange={this.handleChange}
              inputProps={{
                name: "operario",
              }}
              onFocus={this.handleOnFocus}
            />
            <br />
            <br />
            {/* <TextField
              hintText="Password"
              floatingLabelText="Password"
              value={this.state.clave}
              id="clave"
              onChange={(e, value) => this.setState({clave: value})}
              type="password"
              autoFocus
            /><br /> */}

            <FormControl fullWidth>
              <InputLabel htmlFor="adornment-password">Password</InputLabel>
              <Input
                id="clave"
                type={this.state.showPassword ? "text" : "password"}
                value={this.state.clave}
                onChange={this.handleChange}
                autoFocus
                inputProps={{
                  name: "clave",
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Toggle password visibility"
                      onClick={(e, value) =>
                        this.setState({
                          showPassword: !this.state.showPassword,
                        })
                      }
                    >
                      {this.state.showPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                onFocus={this.handleOnFocus}
              />
            </FormControl>

            <h5
              style={{ visibility: !this.props.visible ? "hidden" : "visible" }}
            >
              Credenciales incorrectas
            </h5>
            <Button
              variant="contained"
              color="secondary"
              style={styles.button}
              onClick={this.botonClick}
            >
              <ActionLogin />
              Login
            </Button>
          </div>
        );
      } else if (this.props.cabecera === "Cambiar clave") {
        return (
          <div>
            <FormControl fullWidth>
              <InputLabel htmlFor="adornment-password">Password</InputLabel>
              <Input
                id="adornment-password"
                type={this.state.showPassword ? "text" : "password"}
                value={this.state.newPass1}
                onChange={this.handleChange}
                inputProps={{
                  name: "newPass1",
                }}
                placeholder="Nueva clave"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Toggle password visibility"
                      onClick={(e, value) =>
                        this.setState({
                          showPassword: !this.state.showPassword,
                        })
                      }
                    >
                      {this.state.showPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                onFocus={this.handleOnFocus}
              />
            </FormControl>

            <FormControl fullWidth>
              <InputLabel htmlFor="adornment-password">Password</InputLabel>
              <Input
                id="adornment-password"
                type={this.state.showPassword ? "text" : "password"}
                value={this.state.newPass2}
                onChange={this.handleChange}
                inputProps={{
                  name: "newPass2",
                }}
                placeholder="Repetir clave"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Toggle password visibility"
                      onClick={(e, value) =>
                        this.setState({
                          showPassword: !this.state.showPassword,
                        })
                      }
                    >
                      {this.state.showPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                onFocus={this.handleOnFocus}
              />
            </FormControl>

            {/*         <TextField
              hintText="Password"
              floatingLabelText="Nueva contraseña"
              value={this.state.newPass1}
              id="Clave"
              onChange={(e, value) => this.setState({newPass1: value})}
              type="password"
              autoFocus
            /><br />
            <TextField
              hintText="Password"
              floatingLabelText="Repetir la contraseña"
              value={this.state.newPass2}
              id="NewClave"
              onChange={(e, value) => this.setState({newPass2: value})}
              type="password"
            /><br /> */}

            {/* <RaisedButton
              label="Guardar"
              primary={true}
              style={styles.button}
              icon={<ActionSettings />}
              onClick={this.botonClick}
            /> */}
            <Button
              variant="contained"
              color="primary"
              style={styles.button}
              onClick={this.botonClick}
            >
              <ActionSettings />
              Guardar
            </Button>
          </div>
        );
      } else if (this.props.cabecera === "¿Cerrar sesión existente?") {
        const actions = [
          <Button
            key="1"
            variant="contained"
            color="secondary"
            style={styles.button}
            onClick={this.botonClick1}
          >
            <ActionStart />
            Sí, iniciar nueva sesión
          </Button>,
          <MuiThemeProvider theme={themeRed}>
            <Button
              key="2"
              variant="contained"
              color="primary"
              style={styles.button}
              onClick={this.botonClick2}
            >
              <ActionCancel />
              No, cambiar de usuario
            </Button>
          </MuiThemeProvider>,
        ];
        return (
          // <div>
          //   <button className="yesOverride" onClick={this.botonClick1}>Sí, iniciar nueva sesión</button>
          //   <button className="noOverride" onClick={this.botonClick2}>No, cambiar de usuario</button>
          // </div>
          <div>
            <Dialog
              title="Iniciar sesión de todas formas"
              actions={actions}
              modal="true"
              open={true}
            >
              Ya hay una sesión iniciada con ese usuario, ¿quieres cerrar su
              sesión e iniciar una nueva?
            </Dialog>

            <Dialog
              open={true}
              TransitionComponent={Transition}
              keepMounted
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle id="alert-dialog-slide-title">
                {"Iniciar sesión de todas formas"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                  Ya hay una sesión iniciada con ese usuario, ¿quieres cerrar su
                  sesión e iniciar una nueva?
                </DialogContentText>
              </DialogContent>
              <DialogActions>{actions}</DialogActions>
            </Dialog>
          </div>
        );
      } else if (this.props.cabecera === "Inicio sesión") {
        console.log("state.maquina: -" + this.state.maquina + "-");
        console.log("state.almacen: -" + this.state.almacen + "-");
        console.log("state.idioma: -" + this.state.idioma + "-");
        while (
          this.props.data !== null &&
          this.props.data.payload.loginFields.warehouses !== undefined
        ) {
          let warehouses = this.props.data.payload.loginFields.warehouses.map(
            (element, index) => {
              return (
                <MenuItem value={element} key={element}>
                  {element}
                </MenuItem>
              );
            }
          );
          let machines = this.props.data.payload.loginFields.machines.map(
            (element, index) => {
              return (
                //<option key={index}>{element}</option>
                <MenuItem value={element} key={element}>
                  {element}
                </MenuItem>
              );
            }
          );
          let languages = this.props.data.payload.loginFields.languages.map(
            (element, index) => {
              return (
                <MenuItem value={element} key={element}>
                  {element}
                </MenuItem>
              );
            }
          );
          return (
            <div>
              <AppBar
                position="static"
                color="secondary"
                className={classes.contentAppBar}
              >
                <Toolbar>
                  <Typography variant="h6" color="inherit">
                    Configurar terminal
                  </Typography>
                </Toolbar>
              </AppBar>
              <br />
              <div>
                <FormControl fullWidth>
                  <InputLabel>Almacén</InputLabel>
                  <SelectField
                    value={this.state.almacen}
                    onChange={this.handleChange}
                    inputProps={{
                      name: "almacen",
                      id: "almacen",
                    }}
                  >
                    <MenuItem value="" disabled>
                      Elegir un almacén
                    </MenuItem>
                    {warehouses}
                  </SelectField>
                </FormControl>
              </div>
              <br />
              <div>
                <FormControl fullWidth>
                  <InputLabel>Máquina</InputLabel>
                  <SelectField
                    value={this.state.maquina}
                    onChange={this.handleChange}
                    inputProps={{
                      name: "maquina",
                      id: "maquina",
                    }}
                  >
                    {machines}
                  </SelectField>
                </FormControl>
              </div>
              <br />
              <div>
                <FormControl fullWidth>
                  <InputLabel>Idioma</InputLabel>
                  <SelectField
                    value={this.state.idioma}
                    onChange={this.handleChange}
                    inputProps={{
                      name: "idioma",
                      id: "idioma",
                    }}
                  >
                    {languages}
                  </SelectField>
                </FormControl>
              </div>
              <br />
              {/*<div><SelectField
                              floatingLabelText="Almacén" 
                              id="almacen"
                              value={this.state.almacen}
                              onChange={(e, index, value) => this.handleChangeSelect("almacen", value)}>
                                {warehouses}
                            </SelectField></div>
              <div><SelectField
                              floatingLabelText="Máquina" 
                              id="maquina"
                              value={this.state.maquina}
                              onChange={(e, index, value) => this.handleChangeSelect("maquina", value)}>
                                {machines}
                            </SelectField></div>
              <div><SelectField
                              floatingLabelText="Idioma" 
                              id="idioma"
                              value={this.state.idioma}
                              onChange={(e, index, value) => this.handleChangeSelect("idioma", value)}>
                                {languages}
                            </SelectField>
               <select id="idioma">{languages}</select> 
              </div>
              <RaisedButton
                label="Comenzar"
                primary={true}
                style={styles.button}
                icon={<ActionStart />}
                onClick={this.botonClick}
              />*/}
              <Button
                variant="contained"
                color="primary"
                style={styles.button}
                onClick={this.botonClick}
              >
                <ActionStart />
                Comenzar
              </Button>
            </div>
          );
        }
        return null;
      } else if (this.props.cabecera === "Menú") {
        console.log("CurrentMenuItem en Principal", this.props.currentMenuItem);
        if (
          this.props.currentMenuItem !== undefined &&
          this.props.currentMenuItem.children !== undefined
        ) {
          console.log(
            "Ha entrado a children en Principal",
            this.props.currentMenuItem
          );
          let menuItems = [];
          menuItems.push(
            this.props.currentMenuItem.children.map((element, index) => {
              let e = element.item_text;
              if (element.hasChild) {
                //si hay hijos bajo ese boton que estoy preparando solo le paso el index del boton que clicko
                //si no hay hijos le paso el index, que realmente no sirve para nada mas que para saber cual pintar, y la item_key que ira en al json
                return (
                  // <MenuItemCore key={index} onClick={()=>this.props.appClick(this.props.cabecera, index)}>{e}</MenuItemCore>
                  <Row key={index} style={{ display: "grid" }}>
                    <Button
                      variant="contained"
                      className={classes.button}
                      onClick={() =>
                        this.props.appClick(
                          this.props.cabecera,
                          index,
                          true,
                          element
                        )
                      }
                    >
                      {e}
                    </Button>
                  </Row>
                );
              } else {
                return (
                  // <MenuItemCore key={index} onClick={()=>this.props.appClick(this.props.cabecera, index, element.item_key)}>{e}</MenuItemCore>
                  <Row key={index} style={{ display: "grid" }}>
                    <Button
                      variant="contained"
                      className={classes.button}
                      onClick={() =>
                        this.props.appClick(
                          this.props.cabecera,
                          index,
                          false,
                          element.item_key
                        )
                      }
                    >
                      {e}
                    </Button>
                  </Row>
                );
              }
            })
          );
          return (
            <div>
              {/*<Row>{this.props.currentMenuItem.item_text}</Row>*/}
              <AppBar
                position="static"
                color="secondary"
                className={classes.contentAppBar}
              >
                <Toolbar>
                  <Typography variant="h6" color="inherit">
                    {this.props.currentMenuItem.item_text}
                  </Typography>
                </Toolbar>
              </AppBar>
              <br />
              {menuItems}
              <br />
              <Row>
                <Button
                  disabled={!this.props.volverEnabled}
                  variant="contained"
                  color={colorVolver}
                  className={classes.button}
                  onClick={this.volverClick}
                >
                  Volver
                </Button>
              </Row>
              {/* <Row>
                <button onClick={this.botonClick}>Seleccionar</button>
                <button onClick={this.arribaClick}>Arriba</button>
                <button onClick={this.abajoClick}>Abajo</button>
              </Row> */}
            </div>
          );
        } else {
          return null;
        }
      } else if (this.props.cabecera === "Acción") {
        if (
          this.props.data !== null &&
          this.props.data.payload.data_code === "ACTION_NEW" &&
          this.props.data.payload.screenContent !== undefined
        ) {
          if (
            !this.state.scannerTagUsed &&
            this.props.data.payload.screenContent.message.includes("[QR]") &&
            !this.props.scanner
          ) {
            this.setState({
              scannerTagUsed: true,
            });
            this.props.scannerClick();
          }
          let botones = this.props.data.payload.screenContent.keys.map(
            (element, index) => {
              let texto =
                element.code +
                ": " +
                (element.text.match(/\[([^\]]+)\]/) != null)
                  ? element.text.match(/\[([^\]]+)\]/)[1]
                  : element.text;
              return (
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  key={index}
                  onClick={() =>
                    this.props.appClick(
                      this.props.cabecera,
                      index,
                      undefined,
                      this.state.inputTerminal ? this.state.inputTerminal : "0",
                      element.code
                    )
                  }
                >
                  {texto}
                </Button>
              );
            }
          );
          let scannerBlock = (
            <section style={containerStyle}>
              <div>
                <div style={viewFinderStyle}></div>
                <video
                  style={videoPreviewStyle}
                  autoPlay
                  //width={this.props.width}
                  //height={this.props.height}
                  src={this.state.src}
                  muted={true}
                  className={this.props.className}
                  //style={this.props.style}
                  ref={(ref) => (this.video = ref)}
                />
              </div>
            </section>
          );
          let hasOptions = this.hasOptions(
            this.props.data.payload.screenContent.content
          );
          let isVoiceSpeech = this.isVoiceSpeech(
            this.props.data.payload.screenContent.content
          );

          const { transcript, listening } = this.props;

          let formattedTranscript = this.mixedTextToNumber(transcript);
          return (
            <div>
              <AppBar
                position="static"
                color="secondary"
                className={classes.contentAppBar}
              >
                <Toolbar>
                  <Typography variant="h6" color="inherit">
                    {this.props.data.payload.screenContent.title.match(
                      /\[([^\]]+)\]/
                    ) != null
                      ? this.props.data.payload.screenContent.title.match(
                          /\[([^\]]+)\]/
                        )[1]
                      : this.props.data.payload.screenContent.title}
                  </Typography>
                </Toolbar>
              </AppBar>
              <br />
              {this.props.scanner && scannerBlock}
              {/* <Row><div>{this.props.data.payload.screenContent.title}</div></Row><br /> */}
              <Row>
                <div style={{ textAlign: "left" }}>
                  {/*this.props.data.payload.screenContent.content*/}
                  {!hasOptions &&
                    !isVoiceSpeech &&
                    this.formatText(
                      this.props.data.payload.screenContent.content
                    )}
                  {hasOptions && <span>Elige una opción</span>}
                  {isVoiceSpeech &&
                    this.getVoiceSpeech(
                      this.props.data.payload.screenContent.content
                    )}
                  {/* {this.props.data.payload.screenContent.content.split('\n').map((item, key) => {
                      return <span key={key}>{(item.match(/\[([^\]]+)\]/) != null)? item.match(/\[([^\]]+)\]/)[1] : item}<br/></span>
                    })} */}
                </div>
              </Row>
              <br />
              <Divider />
              <br />
              <Row>
                <div style={{ textAlign: "left" }}>
                  {this.props.data.payload.screenContent.message
                    .split("\n")
                    .map((item, key) => {
                      return (
                        <span key={key}>
                          {item.match(/\[([^\]]+)\]/) != null
                            ? item.match(/\[([^\]]+)\]/)[1]
                            : item}
                          <br />
                        </span>
                      );
                    })}
                </div>
              </Row>
              <br />
              <Divider />
              <br />
              <Row style={{ display: "flex", flexDirection: "row" }}>
                {/* <input type="text" className="menu-input" name="menu-input" id="menu-input" defaultValue="123"/> */}
                {!hasOptions && (
                  <>
                    <TextField
                      placeholder="Input terminal"
                      label="Input"
                      id="menu-input"
                      value={
                        listening
                          ? this.state.inputTerminal + formattedTranscript
                          : this.state.inputTerminal
                      }
                      onChange={this.handleChange}
                      inputProps={{
                        name: "inputTerminal",
                      }}
                      onKeyDown={(e) => {
                        //console.log(e.keyCode);
                        if (e.keyCode == 13) {
                          this.props.appClick(
                            this.props.cabecera,
                            1,
                            undefined,
                            this.state.inputTerminal
                              ? this.state.inputTerminal
                              : "0",
                            7
                          );
                        }
                      }}
                      onFocus={this.handleOnFocus}
                    />
                    <div
                      onClick={this.handleMicClick}
                      style={{
                        cursor: "pointer",
                        //border: "2px solid rgba(0, 0, 0, 0.26)",
                        // borderRadius: "50%",
                        padding: "0px", // Ajusta el padding según sea necesario
                        width: "24px",
                        height: "auto",
                        marginLeft: "10px",
                        background: "white",
                        marginTop: "0px",
                        position: "relative",
                      }}
                    >
                      <MicIcon
                        style={{
                          color: this.state.micIsActive ? "#509605" : "gray",
                          position: "absolute",
                          bottom: "0",
                          left: "5px",
                          fontSize: "xxl",
                        }}
                      />
                    </div>
                  </>
                )}
                {hasOptions &&
                  this.buildOptionsSelect(
                    this.props.data.payload.screenContent.content
                  )}
                <br />
                <br />
              </Row>
              <Row>{botones}</Row>
            </div>
          );
        }
      } else {
        return null;
      }
    }
  }

  arribaClick() {
    if (this.state.seleccion > 0) {
      let aux = this.state.seleccion - 1;
      this.setState({
        seleccion: aux,
      });
    }
  }

  abajoClick() {
    if (this.state.seleccion < 2) {
      let aux = this.state.seleccion + 1;
      this.setState({
        seleccion: aux,
      });
    }
  }

  volverClick() {
    console.log("¡VolverClick!");
    this.props.volverClick();
  }
  handleChangeSelect(select, value) {
    if (select === "almacen") {
      this.setState({
        almacen: value,
      });
    } else if (select === "maquina") {
      this.setState({
        maquina: value,
      });
    } else if (select === "idioma") {
      this.setState({
        idioma: value,
      });
    }
  }

  handleChange = (event) => {
    console.log("handleChange: " + event.target + "->" + event.target.value);
    console.log("target", event.target.name);
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleOnFocus() {
    if (document.activeElement) {
      this.props.lastFocusedElement(document.activeElement);
    }
  }

  botonClick() {
    if (this.props.cabecera === "Login") {
      if (
        this.state.empresa === "" ||
        this.state.operario === "" ||
        this.state.clave === ""
      ) {
        window.alert("Rellena todo el formulario");
      } else {
        this.props.appClick(this.props.cabecera, this.state);
      }
    } else if (this.props.cabecera === "Inicio sesión") {
      this.props.appClick(this.props.cabecera, this.state);
    } else if (this.props.cabecera === "Cambiar clave") {
      if (this.state.newPass1 === this.state.newPass2) {
        this.props.appClick(this.props.cabecera, this.state);
      } else {
        window.alert("Las contraseñas no coinciden");
      }
    } else if (this.props.cabecera === "Menú") {
      this.props.appClick(this.props.cabecera, this.state.seleccion);
    }
  }
  botonClick1() {
    console.log("botonClick1 en Principal: -Y-");
    this.props.appClick(this.props.cabecera, document, "Y");
  }
  botonClick2() {
    console.log("botonClick2 en Principal: -N-");
    this.props.appClick(this.props.cabecera, document, "N");
  }
  handleKeyboardKeyPressed = (button) => {
    console.log("Button pressed en Principal:", button);
  };

  scannerClick() {
    if (this.barcodeLoaded && this.props.scanner) {
      this.props.scannerClick();
    }
  }
}

class Analyzer {
  constructor(video) {
    this.video = video;

    this.imageBuffer = null;
    this.sensorLeft = null;
    this.sensorTop = null;
    this.sensorWidth = null;
    this.sensorHeight = null;

    this.canvas = document.createElement("canvas");
    this.canvas.style.display = "none";
    this.canvasContext = null;

    this.decodeCallback = ZXing.Runtime.addFunction(function (
      ptr,
      len,
      resultIndex,
      resultCount
    ) {
      let result = new Uint8Array(ZXing.HEAPU8.buffer, ptr, len);
      let str = String.fromCharCode.apply(null, result);
      if (resultIndex === 0) {
        window.zxDecodeResult = "";
      }
      window.zxDecodeResult += str;
    });
  }

  analyze() {
    if (!this.video.videoWidth) {
      return null;
    }

    if (!this.imageBuffer) {
      let videoWidth = this.video.videoWidth;
      let videoHeight = this.video.videoHeight;

      this.sensorWidth = videoWidth;
      this.sensorHeight = videoHeight;
      this.sensorLeft = Math.floor(videoWidth / 2 - this.sensorWidth / 2);
      this.sensorTop = Math.floor(videoHeight / 2 - this.sensorHeight / 2);

      this.canvas.width = this.sensorWidth;
      this.canvas.height = this.sensorHeight;

      this.canvasContext = this.canvas.getContext("2d");
      this.imageBuffer = ZXing._resize(this.sensorWidth, this.sensorHeight);
      return null;
    }

    this.canvasContext.drawImage(
      this.video,
      this.sensorLeft,
      this.sensorTop,
      this.sensorWidth,
      this.sensorHeight
    );

    let data = this.canvasContext.getImageData(
      0,
      0,
      this.sensorWidth,
      this.sensorHeight
    ).data;
    for (let i = 0, j = 0; i < data.length; i += 4, j++) {
      let [r, g, b] = [data[i], data[i + 1], data[i + 2]];
      ZXing.HEAPU8[this.imageBuffer + j] = Math.trunc((r + g + b) / 3);
    }
    try {
      let err = ZXing._decode_qr(this.decodeCallback);

      if (err) {
        return null;
      }
    } catch (err) {
      console.log(err);
    }

    let result = window.zxDecodeResult;
    if (result != null) {
      return { result: result, canvas: this.canvas };
    }

    return null;
  }
}

Principal.propTypes = {
  classes: PropTypes.object.isRequired,
  audio: PropTypes.bool,
  muted: PropTypes.bool,
  onUserMedia: PropTypes.func,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  screenshotFormat: PropTypes.oneOf(["image/webp", "image/png", "image/jpeg"]),
  style: PropTypes.object,
  className: PropTypes.string,
  audioSource: PropTypes.string,
  videoSource: PropTypes.string,
};

export default withStyles(styles)(Principal);
