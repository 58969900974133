import React from 'react';
import AppBar from '@material-ui/Core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import SelectField from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const WORKSPACES = [
  <MenuItem value={"online"} key={"online"}>Online</MenuItem>,
  <MenuItem value="local" key="local">Local</MenuItem>
];
export default class Superior extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      workspace: "online"
    }
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange = (event) => {
    // console.log("handleChange: " + event.target + "->" + event.target.value);
    // console.log("target",event.target.name);
    this.setState({
      workspace: event.target.value
    });
    this.props.main.setWorkspace(event.target.value);
  }
  render() {
    const styles = {
      title: {
        cursor: 'pointer',
      },
      header: {
        padding: "10px",
        textAlign: "center",
        position: "fixed",
        top: "0",
        left: "0"
      }
    };
    return (
      <header className="titulo">
        {/*<b>{this.props.cabecera}</b>*/}     
        <AppBar position="static" justify="center" style={styles.header}>
          {/* <div>                
            <FormControl fullWidth>
              <InputLabel>Entorno</InputLabel>
              <SelectField
                value={this.state.workspace}
                onChange={this.handleChange}
                inputProps={{
                  name: 'workspace',
                  id: 'workspace',
                }}
              >
                  <MenuItem value="" disabled>
                    Elegir un entorno
                  </MenuItem>
                  {WORKSPACES}
              </SelectField>
            </FormControl>
          </div><br /> */}
            <Typography variant="h6" color="inherit">
              <span style={styles.title}>{this.props.cabecera}</span>
            </Typography>
        </AppBar>   
      </header>
    );  }

}
